<template>
	<div class="details">
		<Commshop
		 :proData="transmitData"
		  />
		
		<div class="conten_body">  
			<div class="conten_left">
				
            
         <!-- 为什么选择万方？万方有什么优势？ -->
				<div class="con_list">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_01.jpg">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_02.jpg">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_03.jpg">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_04.jpg">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_05.jpg">
					<img class="" src="@/assets/images/testing/4-1/B4-20190410_06.jpg">
					
					
				</div>
				
				
			</div>
			<!-- right -->
			<Phone/>
			

		</div>
	</div>
</template>

<script type="text/javascript">
import Phone from "@/components/Phone.vue"
import Commshop from "@/components/Commshop.vue"
export default{
	components:{
      Phone,
      Commshop
	},
data(){
	return{
		transmitData:{
			title:"WEB渗透测试",
			content:"APP渗透测试，寻找并利用信息系统存在的安全漏洞，模拟恶意攻击者的攻击方法，以“非法”方式“入侵”信息系统，从而确认安全漏洞的存在及安全等级，对信息系统的安全进行评估，提出相应的安全对策",
            cc:"APP渗透测试，寻找并利用信息系统存在的安全漏洞，模拟恶意攻击者的攻击方法，以“非法”方式“入侵”信息系统，从而确认安全漏洞的存在及安全等级，对信息系统的安全进行评估，提出相应的安全对策",
			price:'在线咨询',
			url:require('../../assets/images/testing/4-1/WE.jpg')
		}
	}
},
mounted(){

},
methods:{
	

}


};
</script>

<style type="text/css" lang="less" scoped>
.details{
	width:1200px;
	margin:0 auto;

//**/
.conten_body{
	margin-top:30px;
	overflow: hidden;
}
.conten_left{
	width:880px;
	float:left;
	background:#fff;
	box-sizing: border-box;
}
.nav_list{
	height:39px; 
   border-bottom: 1px solid #CFCDCD;
}
.nav_list li{
	float:left;
	width:120px;
	text-align:center;
}
.nav_list li a{
	display: inline-block;
	height:39px;
	font-size: 15px;
	line-height: 39px;
	color: #007CC6;
}
.nav_list li  .a_active{
   color:#007CC6;
   border-bottom:1px solid #007CC6;
}
.con_list{
	// padding:0 35px;
	overflow:hidden;
}
.con_list img{
	width:100%;
}





}


</style>







